import React, { useEffect, useRef } from 'react';

export default (WrappedComponent) => {
  const TABBABLE_ELEMENTS = 'a[href], button';
  const KEYCODE_ESC = 27;
  const KEYCODE_TAB = 9;

  const Container = (props) => {
    const keepTab = useRef();

    // Handle ESC key
    useEffect(() => {
      const escKeyListener = (event) => {
        if ((event.which || event.keyCode) === KEYCODE_ESC) {
          props.closeHandler && props.closeHandler();
        }
      };

      document.addEventListener('keydown', escKeyListener, false);

      return () => {
        document.removeEventListener('keydown', escKeyListener, false);
      };
    }, [props.closeHandler]);

    // Focus-trapping logic
    useEffect(() => {
      const keyListener = (event) => {
        const { keyCode, shiftKey, target } = event;

        if (keyCode === KEYCODE_TAB) {
          const tabbableElements = Array.from(
            keepTab.current.querySelectorAll(TABBABLE_ELEMENTS)
          );

          if (tabbableElements.length === 0) return;

          const firstTabbableElement = tabbableElements[0];
          const lastTabbableElement =
            tabbableElements[tabbableElements.length - 1];

          // Move focus to first element that can be tabbed if shift isn't used
          if (target === lastTabbableElement && !shiftKey) {
            event.preventDefault();
            firstTabbableElement.focus();

            // Move focus to last element that can be tabbed if shift is used
          } else if (target === firstTabbableElement && shiftKey) {
            event.preventDefault();
            lastTabbableElement.focus();
          }
        }
      };

      if (keepTab.current) {
        keepTab.current.addEventListener('keydown', keyListener, false);
      }

      return () => {
        if (keepTab.current) {
          keepTab.current.removeEventListener('keydown', keyListener, false);
        }
      };
    }, []);

    const { className } = props;

    return (
      <div ref={keepTab} className={className}>
        <WrappedComponent {...props} />
      </div>
    );
  };

  return Container;
};
